<template>
    <div class="outerBox">
		<div class="itemIcon">
			<img src="@/assets/page2/triangleIcon.png" class="iconImg" alt="">
		</div>
		<div class="itemName">{{content1}}</div>
		<div class="barContainer">
			<div class="redBar" :style="{width: redLength+'%'}" @mousemove.self="mousemoveOnRed" @mouseleave="()=>{handleMouseout(1)}">
				
			</div>
			<div class="yellowBar" :style="{width: yellowLength+'%'}" @mousemove.self="mousemoveOnYellow" @mouseleave="()=>{handleMouseout(2)}">
				
			</div>
			<div class="silverBar" :style="{width: silverLength+'%'}" @mousemove.self="mousemoveOnSilver" @mouseleave="()=>{handleMouseout(3)}">
				
			</div>
			<div class="modal" v-if="modalShow1 || modalShow2 || modalShow3" :style="{left: modalX, top: modalY+'px'}">
				<span class="modalName">{{modalName}}</span>
				<span class="modalValue">{{modalValue}}</span>
			</div>
		</div>
		<active-number class="totalBox" :number="totalCount"></active-number>
	</div>
</template>

<style lang="less" scoped>
.outerBox {
	display: flex;
	align-items: center;
	position: relative;

	.itemIcon {
		height: 100%;

		.iconImg {
			width: 100%;
			height: 100%;
		}
	}

	.itemName {
		height: 100%;
		font-size: 1.3vh;
		line-height: 1.3vh;
		color: #fff;
		font-family: 'pingfangSc', sans-serif;
		display: flex;
		align-items: center;
	}
	.barContainer {
		margin-left: 2.11%;
		width: 66.84%;
		height: 38.1%;
		background: #2c230e;
		display: flex;
		position: relative;

		.redBar {
			height: 100%;
			background: linear-gradient(270deg, #ed4242 0%, rgba(237,66,66,0.2) 100%);
			transition: width 1.2s;
			cursor: pointer;
		}

		.yellowBar {
			height: 100%;
			background: linear-gradient(270deg, #ffd03b 0%, rgba(255,208,59,0.2) 100%);
			transition: width 1.2s;
			cursor: pointer;
		}

		.silverBar {
			height: 100%;
			background: linear-gradient(270deg, #d9e7ff 0%, rgba(217,231,255,0.2) 100%);
			transition: width 1.2s;
			cursor: pointer;
		}
	}
	.totalBox {
		position: absolute;
		right: 0;
		height: 100%;
		display: flex;
		align-items: center;
		font-size: 1.48vh;
		line-height: 1.48vh;
		font-family: 'titleFont', sans-serif;
		background: linear-gradient(180deg, #fff 42%, #ffd03b 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		letter-spacing: 1px;
	}

	.modal {
		position: absolute;
		width: 40.73%;
		height: 2.96vh;
		display: flex;
		align-items: center;
		justify-content: center;
		background: linear-gradient(90deg, rgba(72,64,42,0.4) 0%, rgba(61,61,61,0) 100%);
		border: 1px solid;
		border-image: linear-gradient(270deg, rgba(255, 208, 59, 1), rgba(255, 208, 59, 0.2)) 1 1;
		pointer-events: none;
		z-index: 999;

		.modalName {
			line-height: 1.944vh;
			font-size: 1.3vh;
			font-family: 'pingfangSc', sans-serif;
			font-weight: 400;
			color: rgba(255,255,255,0.7);
		}
		.modalValue {
			line-height: 2.22vh;
			font-size: 1.48vh;
			font-family: 'titleFont', sans-serif;
			font-weight: 400;
			background: linear-gradient(180deg, #fff 42%, #ffd03b 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			margin-left: 7.92%;
		}
	}

}
</style>

<script>
const activeNumber = () => import('@/views/components/activeNumber.vue');

export default {
	props:{
		itemName:{
			type: String
		},
		dataInput:{
			type: Object
		},
		biggest:{
			type: Number
		}
	},
	components: {
		activeNumber
	},
	data(){
		return {
			content1: '',
			redLength: 0,
			yellowLength: 0,
			silverLength: 0,
			maxCount: 0,
			totalCount: 0,
			modalX: 0,
			modalY: 0,
			modalValue: 0,
			modalShow1: false,
			modalShow2: false,
			modalShow3: false,
			modalName: ''
		}
	},
	mounted(){
		this.initData();
		return false;
	},
	watch: {
		dataInput(){
			this.initData();
			return false;
		}
	},
	methods: {
		initData(){
			this.content1 = this.itemName;
			let m = this.biggest;
			m = m - m%5 + 5;
			this.maxCount = m;
			let {young, middle, elder} = this.dataInput;
			this.redLength = young * 100 / m;
			this.yellowLength = middle * 100 / m;
			this.silverLength = elder * 100 / m;
			this.totalCount = young + middle + elder;
		},
		mousemoveOnRed(ev){
			this.modalShow1 = true;
			this.modalName = '30岁以下';
			this.modalX = ev.offsetX+2+'px';
			this.modalY = ev.offsetY;
			this.modalValue = this.dataInput.young;
			return false;
		},
		mousemoveOnYellow(ev){
			this.modalShow2 = true;
			this.modalName = '30-50岁';
			this.modalX = 'calc('+this.redLength+'% + '+(ev.offsetX+2)+'px)';
			this.modalY = ev.offsetY;
			this.modalValue = this.dataInput.middle;
			return false;
		},
		mousemoveOnSilver(ev){
			this.modalShow3 = true;
			this.modalName = '50岁以上';
			this.modalX = 'calc('+this.redLength+'% + '+this.yellowLength+'% + '+(ev.offsetX+2)+'px)';
			this.modalY = ev.offsetY;
			this.modalValue = this.dataInput.elder;
			return false;
		},
		handleMouseout(n){
			if(n == 1){
				this.modalShow1 = false;
			}else if(n == 2){
				this.modalShow2 = false;
			}else if(n == 3){
				this.modalShow3 = false;
			}
			return false;
		}
	}
}
</script>

